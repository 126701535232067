
<script>
import routes from "../router/index";
import Dropdown from "./Dropdown.vue";
import { EventBus } from "../js/EventBus";
import DropdownSearch from "./dropdowns/DropdownSearch.vue";
import LoaderSpinner from "./loaders/LoaderSpinner.vue";
import New from './icons/New.vue';

export default {
  name: "SideBar",
  data() {
    return {
      openDropdown1: false,
      openDropdown2: false,
      openDropdown3: false,
      showCheckoutBuilderModal: false,
      setProducts: [],
      toggleModalOffers: false,
      productsId: null,
      productData: {},
      valueProductsList: "",
      valueOfferList: "",
      offerListData: [],
      chooseCreateCheckout: false,
      offerHasCheckout: false,
      cardSeleted: "newCheckout",
      copyCheckoutTemplate: false,
      productsForCopy: [],
      offerIdFrom: "",
      loaders: {
        copyCheckoutData: null,
        createCheckoutSubmit: null,
      },
      disableCopyCard: false,
      dropdown: {
        affiliate: false,
      },
    };
  },
  components: { Dropdown, DropdownSearch, LoaderSpinner, New },
  methods: {
    saveAsideActive() {
      const asideActive = localStorage.getItem("asideActive");
      if (!asideActive) {
        $("body").addClass("asideOpen");
      }
      if (asideActive == "true") {
        $("body").addClass("asideOpen");
      }
    },
    logout() {
      routes.push("/login");
      this.$store.commit("removeUserData");

      this.$emit("logout");
    },
    asideMobile() {
      var $window = $(window),
        $body = $("body");
      function resize() {
        const asideActive = localStorage.getItem("asideActive");
        if ($window.width() < 1051 || asideActive == "false") {
          $body.removeClass("asideOpen");
          localStorage.removeItem("asideActive");
        }
      }
      $window.resize(resize).trigger("resize");
    },

    getProducts() {
      $("#selectproductsButton .loaderSpinner").show(200);
      this.$axios
        .get("api/products")
        .then((req) => {
          this.setProducts = req.data;
          this.toggleModalOffers = true;
          this.asideMobile();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          $("#selectproductsButton .loaderSpinner").hide(200);
        });
    },

    statusList(data) {
      this.productsId = data.id;
    },

    redirectManageProducts() {
      const productId = this.productsId;
      this.$router.push({
        path: "/produtos/gerenciar-ofertas",
        query: { productId: productId },
      });

      this.toggleModalOffers = false;
    },

    productOfferSlim() {
      $(".checkout-link .loaderSpinner").show(200);
      this.$axios
        .get("/api/offers/product-offer-slim")
        .then((res) => {
          this.showCheckoutBuilderModal = true;
          this.productData = res.data.map((item) => ({
            name: item.productName,
            id: item.productId,
            offers: item.offers,
          }));

          this.valueProductsList = this.productData[0].name;

          this.offerListData = this.productData[0].offers.map((item) => ({
            name: item.offerName,
            id: item.offerId,
            offerHasCheckout: item.offerHasCheckout,
            price: item.price,
          }));

          const allOffersUnchecked = this.productData.every((product) => {
            return product.offers.every((offer) => !offer.offerHasCheckout);
          });

          this.disableCopyCard = allOffersUnchecked;

          this.$store.commit("setProducts", this.productData);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          $(".checkout-link .loaderSpinner").hide(200);
        });
    },
    getValueFromList(data) {
      this.offerId = null;
      this.valueOfferList = "";
      this.productsId = data.id;
      this.valueProductsList = data.name;
      this.offerListData = data.offers.map((item) => ({
        name: item.offerName,
        id: item.offerId,
        offerHasCheckout: item.offerHasCheckout,
        price: item.price,
      }));
    },

    getValueFromOfferList(data) {
      this.offerId = data.id;
      this.valueOfferList = data.name;
      this.offerHasCheckout = data.offerHasCheckout;
    },

    getOfferIdFrom(data) {
      this.offerIdFrom = data.id;
      this.valueOfferList = data.name;
    },

    pushOffersForCopy() {
      this.productsForCopy = this.productData
        .map((item) => ({
          ...item,
          offers: item.offers.filter((offer) => offer.offerHasCheckout == true),
        }))
        .filter((item) => item.offers.length > 0);

      this.valueProductsList = this.productsForCopy[0].name;
      this.offerListData = this.productsForCopy[0].offers.map((item) => ({
        name: item.offerName,
        id: item.offerId,
        offerHasCheckout: item.offerHasCheckout,
        price: item.price,
      }));
    },

    postCopyCheckout() {
      this.loaders.copyCheckoutData = true;
      const copyCheckoutData = {
        offerIdFrom: this.offerIdFrom,
        offerIdTo: this.offerId,
      };

      this.$axios
        .post("/api/Checkouts/copy-chekout", copyCheckoutData)
        .then((res) => {
          if (this.offerId) {
            this.$router.push({
              name: "checkout-builder",
              query: {
                offerId: this.offerId,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loaders.copyCheckoutData = false;
        });
    },

    checkoutSubmit(id) {
      if (
        this.cardSeleted == "newCheckout" ||
        this.cardSeleted == "editCheckout"
      ) {
        this.loaders.createCheckoutSubmit = true;
        const offerId = id;

        if (offerId) {
          this.$router.push({
            name: "checkout-builder",
            query: {
              offerId: id,
            },
          });
        }
      }
      if (this.cardSeleted == "copyCheckout") {
        this.copyCheckoutTemplate = true;
        this.chooseCreateCheckout = false;
      }
    },

    selectCard(card) {
      if (!this.offerHasCheckout) {
        this.cardSeleted = card;
      }

      if (card == "copyCheckout") {
        this.pushOffersForCopy();
      }
    },

    resetInputs() {
      this.valueProductsList = this.productData[0].name;
      this.valueOfferList = "";
    },
    reportIsActive() {
      const allowedRoutes = [
        "/relatorios/relatorio-geral",
        "/relatorios/relatorio-detalhado",
        "/relatorios/relatorio-gerado",
        "/relatorios/relatorio-funil",
      ];
      return allowedRoutes.includes(this.$route.path);
    },

    productIsActive() {
      const allowedRoutes = [
        "/produtos/sou-produtor",
        "/sou-afiliado",
        "/produtos/coproducoes",
        "/produtos/novo-produto",
        "produtos/gerenciar-ofertas",
      ];
      return allowedRoutes.includes(this.$route.path);
    },
  },
  mounted() {
    const $window = $(window),
      $body = $("body");
    function resize() {
      if ($window.width() > 1051) {
        $body.addClass("asideOpen");
        localStorage.removeItem("asideActive");
      }
      if ($window.width() <= 1051) {
        $body.removeClass("asideOpen");
      }
    }
    $window.resize(resize).trigger("resize");
    EventBus.on("chamar-product-offer-slim", this.productOfferSlim);
  },
  created() {
    this.saveAsideActive();
  },
};
</script>

<template>
  <div>
    <aside>
      <router-link to="/home" class="primary-logo-icon"> </router-link>
      <span v-on:click="asideMobile" class="closeButton"> </span>

      <nav>
        <router-link @click="asideMobile" to="/home" class="home-link">
          <i class="bx bx-home-alt"></i>
          Página inicial
        </router-link>
        <router-link @click="asideMobile" to="/vendas" class="mySales-link">
          <i class="bx bx-money"></i>
          Minhas vendas
        </router-link>
        <!-- <router-link
          class="link"
          @click="asideMobile"
          to="/mercado-de-afiliacao"
        >
          <i class="bx bxs-network-chart"></i>
          Mercado de afiliações
          <new/>
        </router-link> -->
        <router-link @click="asideMobile" to="/reembolsos" class="refund-link">
          <i class="bx bx-analyse"></i>
          Reembolsos</router-link
        >
        <div
          @click="openDropdown1 = !openDropdown1"
          :class="[openDropdown1 ? 'openDropdown' : '']"
          class="dropdown-content"
        >
          <a
            @click.prevent
            class="report-link"
            :class="{ 'router-link-active': reportIsActive() }"
          >
            <i class="bx bx-bar-chart"></i>
            Relatórios
            <span class="arrow-icon"></span>
          </a>
          <ul>
            <router-link
              class="link"
              @click="asideMobile"
              to="/relatorios/relatorio-geral"
            >
              Relatório geral
            </router-link>
            <router-link
              class="link"
              @click="asideMobile"
              to="/relatorios/relatorio-detalhado"
            >
              Relatório detalhado
            </router-link>
            <router-link
              class="link"
              @click="asideMobile"
              to="/relatorios/relatorio-gerado"
            >
              Relatórios gerados
            </router-link>
            <router-link
              class="link"
              @click="asideMobile"
              to="/relatorios/relatorio-funil"
            >
              Conversão de funil
            </router-link>
          </ul>
        </div>

        <div
          @click="this.openDropdown2 = !this.openDropdown2"
          :class="[this.openDropdown2 ? 'openDropdown' : '']"
          class="dropdown-content"
        >
          <a
            @click.prevent
            class="products-link"
            :class="{
              'router-link-active':
                productIsActive() ||
                this.$route.path.replace(/\?.*/, '') ==
                  '/produtos/gerenciar-ofertas',
            }"
          >
            <i class="bx bx-package"></i>
            Produtos
            <span class="arrow-icon"></span>
          </a>
          <ul>
            <router-link
              class="link"
              @click="asideMobile"
              to="/produtos/sou-produtor"
            >
              Sou produtor
            </router-link>
            <!-- <router-link class="link" @click="asideMobile" to="/sou-afiliado">
              Sou afiliado
          <new/>

            </router-link> -->
            <router-link
              class="link"
              @click="asideMobile"
              to="/produtos/coproducoes"
            >
              Sou coprodutor
            </router-link>
            <router-link
              class="link"
              @click="asideMobile"
              to="/produtos/novo-produto"
            >
              Cadastrar produto
            </router-link>
            <a
              class="link"
              @click="getProducts(), asideMobile()"
              id="selectproductsButton"
              :class="{
                'router-link-active':
                  this.$route.path.replace(/\?.*/, '') ==
                  '/produtos/gerenciar-ofertas',
              }"
            >
              Gerenciar ofertas
              <span
                class="loaderSpinner"
                style="margin-right: -10px; display: none"
              ></span>
            </a>
          </ul>
        </div>

        <a @click="asideMobile, productOfferSlim()" class="checkout-link">
          <i class="bx bxs-hot"></i>
          Checkout builder
          <span
            class="loaderSpinner"
            style="margin-right: 10px; display: none"
          ></span>
        </a>

        <router-link
          @click="asideMobile"
          to="/upsell-funnels"
          class="upsell-link"
        >
          <i class="bx bxs-hand-up"></i>
          Upsell 1 click</router-link
        >
        <div
          @click="this.openDropdown3 = !this.openDropdown3"
          :class="[this.openDropdown3 ? 'openDropdown' : '']"
          class="dropdown-content"
        >
          <a @click.prevent class="integrations-link">
            <i class="bx bx-link"></i>
            Integrações
            <span class="arrow-icon"></span>
          </a>
          <ul>
            <router-link class="link" to="/integracoes">
              Gerenciar Integrações
            </router-link>
            <router-link class="link" to="/integracoes/historico">
              Histórico
            </router-link>
          </ul>
        </div>
        <router-link
          @click="asideMobile"
          to="/colaboradores"
          class="collaborators-link"
        >
          <i class="bx bx-user-plus"></i>
          Colaboradores</router-link
        >
        <router-link
          @click="asideMobile"
          to="/meu-perfil"
          class="myProfile-link"
        >
          <i class="bx bx-user"></i>
          Meu perfil</router-link
        >
        <router-link @click="asideMobile" to="/financeiro" class="balance-link">
          <i class="bx bx-wallet"></i>
          Saldo e saque</router-link
        >

        <Transition>
          <div
            @click="
              showCheckoutBuilderModal = false;
              copyCheckoutTemplate = false;
              toggleModalOffers = false;
              chooseCreateCheckout = false;
            "
            v-if="
              showCheckoutBuilderModal ||
              copyCheckoutTemplate ||
              toggleModalOffers ||
              chooseCreateCheckout
            "
            class="modal-fade"
          ></div>
        </Transition>
      </nav>

      <Transition name="fadeGrowingCenterFixed">
        <div v-if="toggleModalOffers" class="modalContainer">
          <div class="modal-header">
            <h3>Gerenciar Ofertas</h3>
            <span @click="toggleModalOffers = false" class="closeButton"></span>
          </div>
          <div class="modal-body">
            <dropdown-search
              :listItems="setProducts"
              dropdownId="filterProductList"
              set-label="Gerenciar ofertas de qual produto?"
              setPlaceholder="Selecione o produto"
              @item-clicked="statusList"
            />
          </div>
          <div class="modal-footer">
            <button
              @click="toggleModalOffers = false"
              class="btn secondary-button"
              type="button"
            >
              Cancelar
            </button>

            <button
              type="submit"
              class="btn primary-button"
              @click="redirectManageProducts"
            >
              <span
                class="loaderSpinner loader-white"
                style="display: none"
              ></span>
              Avançar
            </button>
          </div>
        </div>
      </Transition>

      <!-- CheckoutBuilder: Modals checkout builder -->

      <Transition name="fadeGrowingCenterFixed">
        <div v-if="showCheckoutBuilderModal" class="modalContainer">
          <div class="modal-header">
            <h3>Checkout Builder</h3>
            <span
              @click="(showCheckoutBuilderModal = false), resetInputs()"
              class="closeButton"
            ></span>
          </div>
          <div class="modal-body">
            <div class="dropdown-content">
              <dropdown-search
                :listItems="productData"
                dropdownId="filterProductList"
                set-label="Selecione o produto:"
                :setPlaceholder="valueProductsList"
                @item-clicked="getValueFromList"
              />
            </div>

            <dropdown-search
              :listItems="offerListData"
              :resetValue="valueOfferList ?? 'Selecione a oferta'"
              dropdownId="filterOfferList"
              set-label="Selecione a oferta:"
              setPlaceholder="Selecione a oferta"
              @item-clicked="getValueFromOfferList"
            />
          </div>
          <div class="modal-footer">
            <button
              @click="(showCheckoutBuilderModal = false), resetInputs()"
              class="btn secondary-button"
              type="button"
            >
              Cancelar
            </button>
            <button
              @click="
                showCheckoutBuilderModal = false;
                chooseCreateCheckout = true;
              "
              type="submit"
              class="btn primary-button"
              :disabled="valueOfferList && valueProductsList ? false : true"
            >
              <span
                class="loaderSpinner loader-white"
                style="display: none"
              ></span
              >Avançar
            </button>
          </div>
        </div>
      </Transition>

      <!-- CheckoutBuilder: Modal oque você deseja fazer -->

      <Transition name="fadeGrowingCenterFixed">
        <div v-if="chooseCreateCheckout" class="modalContainer chooseCheckout">
          <div class="modal-header">
            <h3>Checkout Builder</h3>
            <span
              @click="(chooseCreateCheckout = false), resetInputs()"
              class="closeButton"
            ></span>
          </div>

          <div class="modal-body">
            <h2>O que você deseja fazer?</h2>

            <div class="checkoutOptionsContent">
              <div
                class="cardContent"
                :class="offerHasCheckout ? 'seleted' : 'disabled'"
                @click="selectCard('editCheckout')"
              >
                <span class="file-icon"></span>
                <h4>Editar checkout já criado</h4>
              </div>

              <div
                class="cardContent"
                :class="[
                  offerHasCheckout || disableCopyCard ? 'disabled' : '',
                  cardSeleted == 'copyCheckout' && !offerHasCheckout
                    ? 'seleted'
                    : '',
                ]"
                @click="selectCard('copyCheckout')"
              >
                <span class="copy-icon"></span>

                <h4>Criar a partir da cópia de outro checkout</h4>
              </div>

              <div
                class="cardContent"
                :class="[
                  offerHasCheckout ? 'disabled' : '',
                  cardSeleted == 'newCheckout' && !offerHasCheckout
                    ? 'seleted'
                    : '',
                ]"
                @click="selectCard('newCheckout')"
              >
                <span class="file-blank-icon"></span>
                <h4>Criar novo checkout do zero</h4>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              @click="
                (chooseCreateCheckout = false),
                  (showCheckoutBuilderModal = true),
                  resetInputs()
              "
              class="btn secondary-button"
              type="button"
            >
              Voltar
            </button>
            <button
              type="submit"
              class="btn primary-button"
              @click="checkoutSubmit(offerId)"
            >
              <Loader-spinner v-if="loaders.createCheckoutSubmit" />

              Avançar
            </button>
          </div>
        </div>
      </Transition>

      <!-- CheckoutBuilder: Copiar o modelo de checkout -->

      <Transition name="fadeGrowingCenterFixed">
        <div v-if="copyCheckoutTemplate" class="modalContainer chooseCheckout">
          <div class="modal-header">
            <h3><i class="bx bx-copy-alt"></i> Copiar modelo de checkout</h3>
            <span
              @click="(copyCheckoutTemplate = false), resetInputs()"
              class="closeButton"
            ></span>
          </div>

          <div class="modal-body">
            <h3>Copiar o modelo de checkout de qual produto e oferta?</h3>

            <div class="dropdown-content">
              <dropdown-search
                :listItems="productsForCopy"
                dropdownId="filterOfferCopyList"
                set-label="Selecione o produto:"
                :setPlaceholder="valueProductsList"
                @item-clicked="getValueFromList"
              />
            </div>

            <div class="dropdown-content">
              <dropdown-search
                :listItems="offerListData"
                dropdownId="filterOfferList"
                set-label="Selecione a oferta:"
                setPlaceholder="Selecione a oferta"
                @item-clicked="getOfferIdFrom"
              />
            </div>
          </div>

          <div class="modal-footer">
            <button
              @click="
                (copyCheckoutTemplate = false),
                  (chooseCreateCheckout = true),
                  resetInputs()
              "
              class="btn secondary-button"
              type="button"
            >
              <i class="bx bx-arrow-back"></i>
              Voltar
            </button>
            <button
              type="submit"
              class="btn primary-button"
              @click="postCopyCheckout"
            >
              <loader-spinner v-if="loaders.copyCheckoutData" />
              Avançar
            </button>
          </div>
        </div>
      </Transition>

      <button v-on:click="logout" class="btn primary-button logoutButton">
        Desconectar
      </button>
    </aside>

    <div v-on:click="asideMobile" class="fade"></div>
  </div>
</template>



<style scoped>
aside {
  width: var(--largeSideBar);
  height: 100vh;
  background-color: var(--white);
  position: fixed;
  top: 0px;
  left: calc(0px - var(--largeSideBar));
  z-index: 65;
  display: flex;
  flex-direction: column;
  transition: var(--slow-main) ease;
  overflow-y: scroll;
  border-right: 1px solid var(--dividing-line-primary);
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
body.asideOpen aside {
  left: 0px;
}

aside .primary-logo-icon {
  left: 1rem;
  top: auto;
  max-width: 65%;
  margin: 20px auto 0px;
  position: relative;
  width: 150px;
  min-height: 25px;
}

aside nav {
  display: flex;
  flex-direction: column;
  margin: 30px 0px 35px;
}

aside nav a {
  border-color: var(--dark-grey);
  color: var(--dark-grey-2);
  font-weight: 500;
  text-align: inherit;
  padding: 0.75rem 1rem;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 14px;
  transition: var(--slow-main) ease all;
  position: relative;
  user-select: none;
  gap: 0.5rem;
  white-space: nowrap;
}

aside nav a::before {
  content: "";
  height: 100%;
  width: 3px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  transition: var(--slow-main) ease all;
}

aside nav a:hover::before {
  background-color: var(--primary-color-main-light);
}

aside nav a:hover {
  color: var(--primary-color-main-light);
}

aside nav a.router-link-active::before {
  background-color: var(--primary-color-main-light);
}

aside nav a.router-link-active {
  color: var(--primary-color-main-light);
}

aside nav a:after,
aside nav a i {
  font-size: 1.25rem;
  opacity: 0.6;
}

aside nav .arrow-icon {
  background-image: url("../assets/arrow-right-red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  display: block;
  transform: translateY(-50%);
  transition: var(--slow-min);
}

aside nav .openDropdown .arrow-icon {
  transform: translateY(-50%) rotate(90deg);
}

.dropdown-content.openDropdown ul {
  max-height: 500px;
}
.dropdown-content.openDropdown li {
  animation: rotateX var(--slow-main) ease-in-out;
  user-select: none;
}

.checkout-link .loaderSpinner {
  position: absolute;
  right: 5px;
}

.dropdown-content .link {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--dividing-line-secondary);
  padding: 0.7rem 1.5rem;
  background-color: var(--white-3);
  color: var(--dark-grey-3);
  opacity: color 0.7;
  font-size: 14px;
  cursor: pointer;
  transition: var(--slow-min);
  overflow: visible;
}

.dark-mode .dropdown-content .link {
  color: var(--white-2);
}

.dropdown-content .link a {
  padding: 0px;
  color: inherit;
  margin: 0px;
  justify-content: space-between;
  width: 100%;
  overflow: visible;
}

.dropdown-content .link a::before,
.dropdown-content .link a::after {
  display: none;
}

body.dark-mode li {
  background-color: rgb(32, 28, 45, 0.75);
  border-bottom: 1px solid var(--dividing-line-secondary);
  --dark-grey-3: rgba(255, 255, 255, 0.85);
}

.dropdown-content .link:hover {
  color: var(--primary-color-main-light);
}

.dropdown-content ul {
  overflow: hidden;
  max-height: 0px;
  transition: var(--slow-main) ease-in-out;
}

.logoutButton {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto auto 40px;
  width: calc(100% - 2rem);
  line-height: 1.6;
  white-space: nowrap;
  user-select: none;
}

.logoutButton::before {
  content: "";
  background-image: url("../assets/noun-log-out-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 18px;
  height: 17px;
  display: inline-block;
}

.modalContainer {
  max-width: 800px;
}

.modal-header {
  align-items: center;
}

.modalContainer .closeButton {
  display: block;
}

.closeButton {
  display: none;
}

.chooseCheckout h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.checkoutOptionsContent {
  display: flex;
  gap: 1rem;
  max-width: 640px;
  margin: auto;
  justify-content: center;
  margin-top: 1.5rem;
}

.checkoutOptionsContent .cardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  max-width: 12rem;
  width: 100%;
  position: relative;
  padding: 1rem 0.5rem;
  transition: 0.15s ease;
  cursor: pointer;
  border: 1px solid #77777777;
  border-radius: 0.5rem;
}

.checkoutOptionsContent .cardContent:hover {
  border-color: var(--secondary-color);
}

.checkoutOptionsContent .cardContent::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
  transition: 0.25s ease;
  border: 2px solid #b5bfd9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
}

.checkoutOptionsContent .cardContent:hover::before {
  transform: scale(1);
  opacity: 1;
}

.checkoutOptionsContent .cardContent.seleted::before {
  transform: scale(1);
  opacity: 1;
}

.checkoutOptionsContent .cardContent.seleted {
  border-color: var(--green-2);
}

.checkoutOptionsContent .cardContent.disabled {
  color: gray;
  cursor: default;
  opacity: 0.4;
  border: 1px solid #77777777;
  pointer-events: none;
}

.checkoutOptionsContent .cardContent.disabled:hover::before {
  display: none;
}

.checkoutOptionsContent .cardContent.seleted::before {
  background-image: url("../assets/bx-check-white.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
  background-color: var(--green-2);
  border-color: var(--green-2);
}

.checkoutOptionsContent .cardContent h4 {
  text-align: center;
  font-weight: 600;
  font-size: 0.95rem;
  color: var(--grey);
  padding: 0rem 0.5rem;
}

.checkoutOptionsContent .cardContent.seleted h4 {
  color: var(--green-2);
}

.cardContent .copy-icon::before,
.cardContent .file-icon::before,
.cardContent .file-blank-icon::before {
  width: 60px;
  height: 60px;
}

.cardContent.seleted .file-icon::before {
  background-image: url("../assets/file-green.svg");
}

.cardContent .copy-icon::before {
  background-image: url("../assets/copy-icon-grey.svg");
}

.cardContent.seleted .copy-icon::before {
  background-image: url("../assets/copy-icon-green.svg");
}

.cardContent .file-blank-icon::before {
  background-image: url("../assets/file-blank-grey.svg");
}

.cardContent.seleted .file-blank-icon::before {
  background-image: url("../assets/file-blank-green.svg");
}

.modal-body h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

h3 .bx {
  font-size: inherit !important;
}

@media screen and (max-width: 1200px) {
  .modalContainer {
    overflow: unset;
  }
}

@media screen and (max-width: 995px) {
  .fade {
    display: block;
  }

  .closeButton {
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 20px;
    width: 15px;
    height: 15px;
  }
}
</style>
