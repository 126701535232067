<script>
!(function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(
  window,
  document,
  "script",
  "https://connect.facebook.net/en_US/fbevents.js"
);
fbq("init", "809461013456227");
fbq("track", "PageView");
fbq("trackCustom", "Dashboard");

(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "GTM-P3ZW2J8K");

import Dropdown from "../components/Dropdown.vue";
import Chart from "../components/Chart.vue";
import placa10k from "@/assets/plates/pulseira-10k-pepper.webp";
import placa100k from "@/assets/plates/placa100k.png";
import placa500k from "@/assets/plates/placa500k.png";
import placa1m from "@/assets/plates/placa1m.png";
import bannerPepper2Bg from "@/assets/pepper-2.0-bg-banner.png"
import pepper2Logo from "@/assets/pepper-2-logo.png"
import { animateValue } from "../js/globalFunctions";
import Badges from "../components/Badges.vue";
import ModalTemplate from "../components/modals/ModalTemplate.vue";
import SkeletonCard from "../components/skeletonCard.vue";
import SkeletonsConatainer from "../components/skeletons/SkeletonsConatainer.vue";

export default {
  name: "Home",
  components: {
    Dropdown,
    Chart,
    Badges,
    ModalTemplate,
    SkeletonCard,
    SkeletonsConatainer,
  },
  data() {
    return {
      LAST_DAYS_MAP: new Map([
        [0, 7],
        [1, 15],
        [2, 30],
      ]),
      dropdownOpen: false,
      reportsHomeData: {},
      lastDaysAmount: null,
      lastFifteenDays: null,
      lastDays: [
        { item: "Últimos 7 dias" },
        { item: "Últimos 15 dias" },
        { item: "Últimos 30 dias" },
      ],
      targets: [
        { amount: 10000, plateName: "META DE 10K", plateImage: placa10k },
        { amount: 100000, plateName: "PLACA DE 100K", plateImage: placa100k },
        { amount: 500000, plateName: "PLACA DE 500K", plateImage: placa500k },
        {
          amount: 1000000,
          plateName: "PLACA DE 1 MILHÃO'",
          plateImage: placa1m,
        },
      ],
      currentTargetIndex: 0,
      currentAmount: null,
      showProgressBar: true,
      badges: null,
      bannerPepper2Logo: pepper2Logo,
    };
  },
  computed: {
    firstName() {
      return this.$store.state.user.firstName;
    },
    missingAmount() {
      return this.targets[this.currentTargetIndex].amount - this.currentAmount;
    },
    progressWidth() {
      return `${(this.currentAmount / this.targets[this.currentTargetIndex].amount) *
        100
        }%`;
    },
    plateName() {
      return this.targets[this.currentTargetIndex].plateName;
    },
    plateImage() {
      return this.targets[this.currentTargetIndex].plateImage;
    },
    bannerPepper20() {
      return {
        width: '100%',
        minHeight: '120px',
        backgroundImage: `url(${bannerPepper2Bg})`,
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#0d060a',
        borderRadius: '0.4rem',
        margin: '0 0 1rem 0',
        border: '1px solid rgb(233 54 54 / 27%)',
        display: 'flex',
        textDecoration: 'none',
      };
    },
    bannerPepper20Overlay() {
      return {
        width: '100%',
        minHeight: '100%',
        backgroundImage: 'linear-gradient(45deg, rgb(124 29 31 / 0%), rgb(129 32 35 / 20%) 60%, rgb(233 54 54 / 31%))',
        borderRadius: '0.4rem',
        padding: '1.5rem 1rem',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
      }
    },
  },

  methods: {
    updateProgress() {
      const target = this.targets.find(
        ({ amount }) => this.currentAmount < amount
      );

      if (!target) {
        this.showProgressBar = false;
        this.currentTargetIndex = this.targets.length - 1;
        return;
      }

      this.showProgressBar = target.amount > this.currentAmount;
      this.currentTargetIndex = this.targets.indexOf(target);
    },

    reportsHome() {
      this.$axios
        .get("/api/reports/home")
        .then((res) => {
          this.badges = res.data.badges;
          this.reportsHomeData = res.data;
          this.currentAmount = res.data.totalAmount;
          this.$store.commit("setVersion", res.data.version);
          this.$store.commit("setTotalAmount", this.currentAmount);

          animateValue(
            this.reportsHomeData.sevenDaysAmount,
            1000,
            (currentValue) => {
              this.reportsHomeData.sevenDaysAmount = currentValue;
            }
          );

          animateValue(
            this.reportsHomeData.thirtyDaysAmount,
            1000,
            (currentValue) => {
              this.reportsHomeData.thirtyDaysAmount = currentValue;
            }
          );

          animateValue(
            this.reportsHomeData.avaliableAmount,
            1000,
            (currentValue) => {
              this.reportsHomeData.avaliableAmount = currentValue;
            }
          );

          animateValue(
            this.reportsHomeData.avaliableAmount,
            1000,
            (currentValue) => {
              this.reportsHomeData.avaliableAmount = currentValue;
            }
          );

          for (let i = 16; i < 31; i++) {
            this.lastFifteenDays +=
              this.reportsHomeData.thirtyDaysSalesByDay[i].sum;
          }
          this.lastDaysAmount = 7;

          this.updateProgress();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async onItemClicked(index) {
      this.lastDaysAmount = this.LAST_DAYS_MAP.get(index);
    },

    logout() {
      this.$router.push("/login");
      this.$store.commit("removeUserData");
      const chatBox = document.querySelector("#crisp-chatbox");
      if (chatBox) {
        chatBox.style.setProperty("display", "none", "important");
      }
    },
  },

  created() {
    this.reportsHome();
  },
};
</script>

<template>
  <section class="containerCenter">
    <noscript><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P3ZW2J8K"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe></noscript>

    <h1>Olá, {{ firstName }}</h1>


    <a href="https://lp.pepper.com.br/sobre-a-pepper-2/" :style="bannerPepper20" class="banner-pepper-20" target="_blank">
      <div :style="bannerPepper20Overlay">
        <div class="banner-pepper-20-content">
          <h4>
            Conheça a
            <img :src="bannerPepper2Logo" />
          </h4>

          <span>
            Afiliação, D+15, Upsell 1 Click PIX e Saques via PIX
          </span>

          <a href="https://app.pepper.com.br/register" target="_blank">
            Clique aqui e cadastre-se
          </a>
        </div>
      </div>
    </a>

    <div style="margin: 1.25rem 0 2rem; display: flex; width: 100%;">
      <a href="https://lp.pepper.com.br/sobre-a-pepper-2/" style="display: flex; align-items: center; gap: 0.5rem; font-weight: 500; font-size: 0.95rem; color: #6366f1; ">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
        </svg>
        Entenda como funciona
      </a>
    </div>

    <div class="chartInformation">
      <div class="card-content">
        <span class="icon-sevenDays"></span>
        <Transition>
          <div
            v-if="reportsHomeData.sevenDaysAmount ||
              reportsHomeData.sevenDaysAmount == 0
              "
            class="card-info-right">
            <h4>
              <span class="inherit">
                R$

                {{
                  reportsHomeData.sevenDaysAmount.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    minimumIntegerDigits: 1,
                  })
                }}
              </span>
            </h4>
            <p>últimos 7 dias</p>
          </div>
          <skeletons-conatainer v-else gap="0.5rem" row="right">
            <skeleton-card width="130px" height="28px" />
            <skeleton-card height="20px" />
          </skeletons-conatainer>
        </Transition>
      </div>

      <div class="card-content">
        <span class="icon-thirtyDay"></span>
        <Transition>
          <div
            v-if="reportsHomeData.thirtyDaysAmount ||
                reportsHomeData.thirtyDaysAmount == 0
                "
            class="card-info-right">
            <h4>
              R$
              <span class="inherit">
                {{
                  reportsHomeData.thirtyDaysAmount.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    minimumIntegerDigits: 1,
                  })
                }}
              </span>
            </h4>
            <p>últimos 30 dias</p>
          </div>
          <skeletons-conatainer v-else gap="0.5rem" row="right">
            <skeleton-card width="130px" height="28px" />
            <skeleton-card height="20px" />
          </skeletons-conatainer>
        </Transition>
      </div>

      <div class="card-content">
        <span class="icon-unavailableBalance"></span>
        <Transition>
          <div
            v-if="reportsHomeData.avaliableAmount ||
                reportsHomeData.avaliableAmount == 0
                "
            class="card-info-right">
            <h4>
              R$
              <span class="inherit">
                {{
                  reportsHomeData.avaliableAmount.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    minimumIntegerDigits: 1,
                  })
                }}
              </span>
            </h4>
            <p>Saldo disponível</p>
          </div>
          <skeletons-conatainer v-else gap="0.5rem" row="right">
            <skeleton-card width="130px" height="28px" />
            <skeleton-card height="20px" />
          </skeletons-conatainer>
        </Transition>
      </div>

      <div class="card-content chart">
        <div class="card-header">
          <div class="card-info">
            <skeleton-card v-if="!lastDaysAmount" width="100px" height="20px" />

            <span v-if="lastDaysAmount == 7" class="daysCount rotateX">
              Últimos 7 dias
            </span>
            <span v-if="lastDaysAmount == 15" class="daysCount rotateX">
              Últimos 15 dias
            </span>
            <span v-if="lastDaysAmount == 30" class="daysCount rotateX">
              Últimos 30 dias
            </span>
            <h2>
              <Transition>
                <div class="inherit" v-if="lastDaysAmount">
                  R$
                  <span class="inherit fadeDown" v-if="lastDaysAmount == 7">
                    {{
                      reportsHomeData.sevenDaysAmount.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        minimumIntegerDigits: 1,
                      })
                    }}
                  </span>

                  <span class="inherit fadeDown" v-if="lastDaysAmount == 15">
                    {{
                      lastFifteenDays.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        minimumIntegerDigits: 1,
                      })
                    }}
                  </span>

                  <span class="inherit fadeDown" v-if="lastDaysAmount == 30">
                    {{
                      reportsHomeData.thirtyDaysAmount.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        minimumIntegerDigits: 1,
                      })
                    }}
                  </span>
                </div>
                <span v-else>
                  <skeleton-card width="160px" height="30px" />
                </span>
              </Transition>
            </h2>
          </div>

          <Dropdown
            :list-items="lastDays"
            set-placeholder=" Últimos 7 dias"
            @item-clicked="onItemClicked"
            dropdown-id="lastDays" />
        </div>

        <div class="chart-content">
          <Chart
            :chart-data="reportsHomeData"
            :last-days-amount="lastDaysAmount" />
        </div>
      </div>

      <div class="card-content infor-content">
        <div class="informationsPlate">
          <div v-if="showProgressBar">
            <h5>Está faltando</h5>
            <h3
              v-if="reportsHomeData.sevenDaysAmount ||
                reportsHomeData.sevenDaysAmount == 0
                ">
              R$
              <span class="inherit">
                {{
                  missingAmount.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    minimumIntegerDigits: 1,
                  })
                }}
              </span>
            </h3>
            <skeleton-card v-else width="160px" height="30px" />
            <p>
              para conquistar a sua <strong>{{ plateName }}</strong>
            </p>
          </div>

          <div v-if="!showProgressBar">
            <h5>Parabéns!!! você conquistou a sua placa de</h5>
            <h3>
              R$
              {{
                targets[currentTargetIndex].amount.toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  minimumIntegerDigits: 1,
                })
              }}
            </h3>
            <h5><strong> de reais em vendas.</strong></h5>
          </div>

          <div v-if="showProgressBar" class="progressBar">
            <div class="progress" :style="{ width: progressWidth }"></div>
          </div>
        </div>

        <div class="image-content">
          <img :src="plateImage" :class="{ dropShadow: currentTargetIndex == 0 }" :alt="`${plateName} ... em vendas`" />
        </div>
      </div>

      <div class="card-content badge-content">
        <Badges :badges="this.badges" v-if="this.badges" />
        <skeletons-conatainer id="skeleton-badges-container" v-else>
          <div class="skeleton-badges"></div>
          <div class="skeleton-badges"></div>
          <div class="skeleton-badges"></div>
          <div class="skeleton-badges"></div>
          <div class="skeleton-badges"></div>
          <div class="skeleton-badges"></div>
        </skeletons-conatainer>
      </div>
    </div>
    <Transition>
      <modal-template
        class="refused-modal"
        v-if="$store.state.user.isRecipientRefused">
        <template #body>
          <p>
            A documentação da sua conta está em análise. Aguarde para começar a
            vender.
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=5551980613317&text=Ol%C3%A1%2C%20fiz%20meu%20cadastro%20na%20Pepper%20e%20minha%20conta%20ainda%20est%C3%A1%20em%20an%C3%A1lise.">
            Fale com a gente
            <i class="bx bxl-whatsapp" style="color: var(--green)"></i>
          </a>
          <button v-on:click="logout" class="btn primary-button logoutButton">
            Desconectar
          </button>
        </template>
      </modal-template>
    </Transition>

    <Transition>
      <div
        v-if="$store.state.user.isRecipientRefused"
        style="cursor: default"
        class="modal-fade"></div>
    </Transition>
  </section>
</template>

<style scoped>
.banner-pepper-20:hover {
  box-shadow: 2px 3px 20px #14102ca8;
}

.dark-mode .banner-pepper-20:hover {
  box-shadow: 2px 3px 20px #34304aa8;
}

.banner-pepper-20,
.banner-pepper-20:hover {
  transition: 0.4s;
}

.banner-pepper-20 {
  background-size: auto 110%;
  background-position: 0 20px;
}

.banner-pepper-20-content {
  color: #FFFFFF;
  width: 1000px;
  max-width: 55vw;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.banner-pepper-20-content h4 {
  color: rgb(202 202 202);
  font-size: 1.1rem;
  font-weight: 400;
  display: inline-flex;
  align-items: end;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.banner-pepper-20-content h4 img {
  height: 1.45rem;
}

.banner-pepper-20-content span {
  color: #ffffff;
  font-weight: 600;
}

.banner-pepper-20-content a {
  color: #f5454d;
  margin-top: 0.1rem;
}

@media screen and (max-width: 995px) {
  .banner-pepper-20 {
    background-size: auto 120px;
  }

  .banner-pepper-20 span {
    font-size: 0.9rem;
  }

  .banner-pepper-20 a {
    font-size: 0.9rem;
  }

  .banner-pepper-20-content {
    max-width: 58vw;
  }

  .banner-pepper-20-content h4 img {
    height: 1.35rem;
  }
}

h1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

h1::before {
  content: "";
  background-image: url("../assets/hand-wave-emoji.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.refused-modal a {
  display: flex;
  gap: 0.5rem;
  color: var(--green);
  font-weight: 700;
  text-decoration: none;
  transition: var(--slow-main);
  align-items: center;
  margin-top: 0.5rem;
}

.refused-modal a:hover {
  opacity: 0.6;
}

.chartInformation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-radius: var(--border-simple);
  max-width: none !important;
}

.card-content .icon-sevenDays,
.card-content .icon-thirtyDay,
.card-content .icon-unavailableBalance {
  display: block;
  background-image: url("../assets/trending-up.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 56px;
  height: 56px;
}

.card-content .icon-thirtyDay {
  background-image: url("../assets/dolar-icon.svg");
}

.card-content .icon-unavailableBalance {
  background-image: url("../assets/wallet-icon-red.svg");
}

.card-content .card-info-right {
  text-align: right;
}

.card-content .card-info-right h4 {
  color: var(--black);
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

.card-content .card-info-right p {
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: -0.1px;
  margin: 0.5rem 0px;
  user-select: none;
}

.card-content:nth-child(n + 4) {
  grid-column: span 3;
}

.card-content .card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-content .card-header .daysCount {
  background-color: rgba(34, 197, 94, 0.12);
  color: #22c55e;
  display: inline-block;
  padding: 0.35em 0.6em;
  font-size: 0.8125em;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--border-simple);
  user-select: none;
}

.card-content .card-header h2 {
  font-size: 2rem;
  color: var(--black);
  margin: 20px 0px 0px;
  user-select: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.dropdown-content {
  width: 33%;
  align-self: flex-end;
}

.card-content.infor-content {
  display: flex;
  z-index: 1;
  overflow: hidden;
  padding: 3rem 2rem;
  grid-column: span 2;
}

.card-content.badge-content {
  grid-column: span 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2rem;
}

.informationsPlate {
  margin-left: 9%;
}

.image-content {
  margin-left: 14%;
}

.informationsPlate {
  width: calc(100% - 9% - 41%);
}

.progressBar {
  height: 5px;
  background-color: #e9ebf4;
  margin-top: 15px;
}

.dark-mode .progressBar {
  background-color: rgba(255, 255, 255, 0.08);
}

.progressBar .progress {
  background-color: var(--green);
  height: 5px;
}

.informationsPlate h5 {
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: -0.1px;
  user-select: none;
}

.informationsPlate h3 {
  color: var(--primary-color-main-dark);
  font-weight: 800;
  letter-spacing: -0.8px;
  font-size: 1.75rem;
  margin-top: 10px;
}

.informationsPlate p {
  font-size: 0.8rem;
  user-select: none;
}

.image-content {
  position: relative;
  width: 41%;
}

.image-content::after {
  content: "";
  width: 800px;
  height: 800px;
  position: absolute;
  right: 0px;
  top: calc(-1.5rem - 100%);
  right: calc(-7rem - 100%);
  background-color: #e5e7f5;
  z-index: -1;
  border-radius: 50%;
  transition: var(--slow-main);
}

.dark-mode .image-content::after {
  background-color: #212034;
}

.image-content img {
  object-fit: cover;
  object-position: center;
  max-width: 170px;
  display: block;
  margin: auto;
  box-shadow: -2px 2px 45px 2px #6366f155;
  border-radius: var(--border-simple);
}

.image-content img.dropShadow {
  box-shadow: none;
  filter: drop-shadow(2px 7px 5px #6366f155);
  max-width: 200px;
  width: 100%;
}


strong {
  font-size: inherit;
}

.card-content.chart {
  flex-direction: column;
}

.chart-content {
  margin-top: 50px;
  width: 100%;
}

#skeleton-badges-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 75%;
  justify-content: space-evenly;
  gap: 2rem;
  flex-direction: row;
}

.skeleton-badges {
  width: 60px;
  height: 60px;
  transform: rotate(45deg);
  border-radius: 10px;
  animation: skeleton-pulse 1s infinite;
  background-image: linear-gradient(45deg, #e0e0e0, rgb(243 243 243), #d4d4d4);
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.6;
  }
}

@media screen and (max-width: 995px) {
  .chartInformation {
    grid-template-columns: 1fr;
  }

  .card-content {
    padding: 1.5rem 1rem;
  }

  .card-content:nth-child(n + 4) {
    grid-column: auto;
  }

  .card-content .card-header {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .dropdown-content {
    width: 100%;
    align-self: flex-start;
  }

  .card-content .card-header h2 {
    font-size: 1.4rem;
    margin: 10px 0px 20px;
  }

  .card-content.infor-content {
    flex-direction: column;
    padding: 3rem 1rem 2rem;
  }

  strong {
    font-size: inherit;
  }

  .informationsPlate {
    text-align: center;
    margin: 0px;
    width: 100%;
  }

  .image-content {
    width: 100%;
    margin: 3rem 0px 0px;
    padding: 0px;
  }

  .image-content::after {
    top: -10%;
    right: -90%;
  }
}
</style>